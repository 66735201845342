<template>
    <div
        class="list"
    >
        <div class="review-content">
            <listing-actions
                v-if="showActions"
                :not-exist-label="$t('page.myReviews.noResult')"
                :page="reviews.page"
            />
            <div
                v-if="canLoad"
                class="items"
            >
                <div
                    v-for="item in reviews.elements"
                    :key="`review-item-${reviews.elements.indexOf(item)}`"
                    class="item"
                >
                    <div class="details d-flex">
                        <router-link
                            :to="{ name: 'Produs', params: { slug: item.product.slug } }"
                        >
                            <div class="image">
                                <img
                                    :src="item.product.thumbnail"
                                    :alt="item.product.name"
                                >
                            </div>
                        </router-link>
                        <div class="content">
                            <div class="d-flex sb">
                                <router-link
                                    :to="{ name: 'Produs', params: { slug: item.product.slug } }"
                                    class="title"
                                >
                                    <p class="sbold">
                                        {{ item.product.name }}
                                    </p>
                                </router-link>
                                <b-button
                                    v-if="!isResponsive"
                                    variant="primary"
                                    class="stroke sm"
                                    @click="openEditReviewModal(item)"
                                >
                                    <edit-icon />{{ $t('page.myReviews.changeButton') }}
                                </b-button>
                                <edit-icon
                                    v-else
                                    class="primary sm"
                                    @click="openEditReviewModal(item)"
                                />
                            </div>
                            <rating-stars
                                :rating="{
                                    value: item.star,
                                }"
                                location="history"
                            />
                            <p class="date sm-sbold">
                                {{ item.review_added }}
                            </p>
                            <p class="sm message">
                                {{ item.message }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <listing-pagination
                v-if="canLoad"
                :page="reviews.page"
                type="review"
            />
        </div>
        <rating-modal
            v-if="showModal"
            :product-id="productId"
            :is-edit="true"
            :rating="rating"
            :message="message"
        />
        <div />
    </div>
</template>

<script>
    import EditIcon from 'vue-feather-icons/icons/EditIcon';
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import RatingStars from '@/components/product/RatingStars';

    import ListingPagination from '../category/ListingPagination';
    import ListingActions from './ListingActions';

    const RatingModal = () => import('@/components/modals/RatingModal');

    export default {
        name: 'ReviewListing',
        components: {
            RatingStars,
            ListingActions,
            ListingPagination,
            RatingModal,
            EditIcon,
        },
        data() {
            return {
                rating: 0,
                productId: 0,
                message: '',
                showModal: false
            };
        },
        computed: {
            ...mapState('user', ['reviews', 'loading']),
            ...mapState('product', ['modal']),
            lang() {
                return i18n.locale;
            },
            isResponsive() {
                return this.$screen.width < 768;
            },
            canLoad() {
                return this.reviews && this.reviews.elements;
            },
            showActions(){
                return !this.loading || (this.loading && Object.keys(this.reviews).length > 0);
            }
        },
        methods: {
            ...mapMutations('product', ['setModal']),
            ...mapActions('user', ['getUserReviews']),
            async loadData() {
                await this.getUserReviews();
            },
            openEditReviewModal(item) {
                this.productId = parseInt(item.product.id);
                this.rating = parseInt(item.star);
                this.message = item.message;
                this.setModal({
                    show: true,
                    type: 'rating',
                });
            },
        },
        created() {
            this.loadData();
        },
        watch: {
            modal: {
                handler(modal) {
                    if(modal.type == 'rating'){
                        this.showModal = modal.show;
                    }
                },
                deep: true,
            },
            lang: {
                handler() {
                    this.loadData();
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.myAccount.reviewsTitle'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
    };
</script>

<style scoped lang="scss">
.review-content {
  .items {
    min-height: 350px;
    padding-top: 24px;
    .item {
      margin-bottom: 24px;
      .details {
        gap: 24px;
        @include media-breakpoint-down(sm) {
          gap: 12px;
        }
        .image {
          background-color: $gray-thin;
          height: 80px;
          width: 120px;
          border-radius: 4px;
          @include media-breakpoint-down(sm) {
            height: 60px;
          }
          img {
            object-fit: contain;
            height: 60px;
          }
        }
        .content {
          width: calc(100% - 144px);
          .title {
            p {
              height: initial;
            }
          }
          p {
            color: $text;
            &.date {
              color: $gray-dark;
            }
            &.message {
              margin-top: 8px;
            }
          }
          .btn {
            margin: 0;
          }
          svg {
            min-width: 20px;
          }
        }
      }
    }
  }
}
</style>
