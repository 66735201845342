<template>
    <div class="container">
        <loading-spinner
            v-if="showLoader"
            class="fixed"
        />
        <b-row>
            <sidebar />
            <b-col
                cols="12"
                md="9"
                class="review-items mtd-40"
                :class="{ transparent: showLoader }"
            >
                <h4>{{ $t('page.myReviews.heading') }}</h4>
                <my-review-listing />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import Sidebar from '../components/page/Sidebar';
    import MyReviewListing from './review/MyReviewListing';

    export default {
        components: {
            Sidebar,
            MyReviewListing,
            LoadingSpinner
        },
        computed:{
            ...mapState('user', ['reviews', 'loading']),
            showLoader() {
                return Object.keys(this.reviews).length == 0 && this.loading;
            },
        },
        name: 'Reviews',
    };
</script>
